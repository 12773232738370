<template>
  <div id="volunteerEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="volunteersData">
              <div class="vx-col w-full">
                <h1 v-if="volunteersData.id">Uredi {{
                    !volunteersData.gender || volunteersData.gender === 'male' ?
                      'prostovoljca' : 'prostovoljko'
                  }} <span v-if="volunteersData.first_name && volunteersData.last_name">"{{ volunteersData.first_name }} {{
                      volunteersData.last_name
                    }}"</span>
                </h1>
                <h1 v-if="!volunteersData.id">Dodaj prostovoljca</h1>
              </div>
            </div>


            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Osnovni podatki</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="vx-row">
                    <div class="vx-col w-full">
                      <vs-input class="w-full" label="Ime" v-model="volunteersData.first_name"/>

                      <div class="flex mt-1" v-if="$v.volunteersData.first_name.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.volunteersData.first_name.required" :color="'danger'">
                            Polje "Ime" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="vx-row mt-5">
                    <div class="vx-col w-full">
                      <vs-input class="w-full" label="Priimek" v-model="volunteersData.last_name"/>

                      <div class="flex mt-1" v-if="$v.volunteersData.last_name.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.volunteersData.last_name.required" :color="'danger'">
                            Polje "Priimek" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div class="grid grid-cols-1 mt-5">
                    <label for="gender" class="vs-input--label mb-2">Spol</label>

                    <ul class="genderPicker" id="gender">
                      <li class="ml-1">
                        <vs-radio v-model="volunteersData.gender" vs-name="gender" vs-value="female">ženski</vs-radio>
                      </li>
                      <li class="ml-10">
                        <vs-radio v-model="volunteersData.gender" vs-name="gender" vs-value="male">moški</vs-radio>
                      </li>
                    </ul>
                  </div>


                  <div class="grid grid-cols-1 mt-5">
                    <label class="vs-input--label">Datum rojstva</label>
                    <datepicker placeholder="Datum rojstva" :language="slSI" :format="customDateFormatter"
                                :monday-first="true"
                                v-model="volunteersData.birthday"></datepicker>
                  </div>

                  <div class="vx-row mt-5">
                    <div class="vx-col w-full">
                      <vs-input class="w-full" type="number" label="Davčna številka" v-model="volunteersData.vat"/>

                      <div class="flex mt-1" v-if="$v.volunteersData.vat.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.volunteersData.vat.required" :color="'danger'">
                            Polje "Davčna številka" ne sme biti prazno.
                          </vs-alert>
                          <vs-alert :active="!$v.volunteersData.vat.minLength || !$v.volunteersData.vat.maxLength"
                                    :color="'danger'">
                            Polje "Davčna številka" mora vsebovati natančno 8 cifer.
                          </vs-alert>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div class="vx-col w-full mt-5" v-if="employmentStatuses">
                    <label for="donorType" class="vs-input--label">Status</label>
                    <v-select id="donorType" :options="employmentStatuses" class=""
                              v-model="employmentStatusId" placeholder="Status"
                              :reduce="item => item.id"
                              label="name">

                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                    <div class="flex mt-1" v-if="$v.employmentStatusId.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.employmentStatusId.required" :color="'danger'">
                          Polje "Status" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <label for="active" class="vs-input--label mb-2">Prostovoljec je aktiven</label>
                    <vs-switch class="ml-1" color="success" v-model="volunteersData.active" id="active">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <label for="onHold" class="vs-input--label mb-2">Prostovoljec je trenutno na čakanju</label>
                    <vs-switch class="ml-1" color="success" v-model="volunteersData.on_hold" id="onHold">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                  <div v-if="$globalFunctions.companionshipSupported()">
                    <div class="flex mt-5 gap-5">
                      <div class="w-1/2">
                        <label for="onHold" class="vs-input--label mb-2">Voznik</label>
                        <vs-checkbox class="mt-2" v-model="volunteersData.isDriver"> aktiven kot prostovoljec-voznik
                        </vs-checkbox>
                      </div>

                      <div class="w-1/2">
                        <label for="onHold" class="vs-input--label mb-2">Spremljevalec</label>
                        <vs-checkbox class="mt-2" v-model="volunteersData.isCompanion"> aktiven kot
                          prostovoljec-spremljevalec
                        </vs-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="flex mt-5 gap-5">
                    <div class="w-1/2">

                      <label class="vs-input--label">Datum začetka prostovoljstva</label>
                      <datepicker placeholder="Datum začetka prostovoljstva" :language="slSI"
                                  :format="customDateFormatter"
                                  :monday-first="true"
                                  v-model="volunteersData.signUpDate"></datepicker>

                    </div>
                    <div class="w-1/2">
                      <label class="vs-input--label">Datum veljavnosti Dogovora o izvajanju prostovoljskega dela</label>
                      <datepicker placeholder="Datum veljavnosti Dogovora o izvajanju prostovoljskega dela"
                                  :monday-first="true"
                                  :language="slSI" :format="customDateFormatter"
                                  v-model="volunteersData.agreementValidityDate"></datepicker>
                    </div>
                  </div>


                  <!--                  <div class="grid grid-cols-1 mt-5">-->
                  <!--                    <label for="vaccinated" class="vs-input&#45;&#45;label mb-2">Prostovoljec je prejel cepivo proti-->
                  <!--                      COVID-19</label>-->
                  <!--                    <vs-switch class="ml-1" color="success" v-model="volunteersData.vaccinated" id="vaccinated">-->
                  <!--                      <span slot="on">DA</span>-->
                  <!--                      <span slot="off">NE</span>-->
                  <!--                    </vs-switch>-->
                  <!--                  </div>-->


                </div>
              </div>
            </div>

            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Naslov bivališča</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1">
                    <label for="volunteersAddress" class="vs-input--label">Naslov</label>
                    <gmap-autocomplete
                      ref="volunteersAddress"
                      id="volunteersAddress"
                      @place_changed="(data) => { getAddressData(data, 'volunteers') }"
                      placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                      :value="volunteersData.addresses.quick_search"
                      :options="{componentRestrictions: { country: 'si' }}"
                      :select-first-on-enter="true"
                      class="vs-inputx vs-input--input normal"></gmap-autocomplete>

                    <div class="flex mt-1" v-if="$v.volunteersData.addresses.quick_search.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.volunteersData.addresses.quick_search.required" :color="'danger'">
                          Polje "Naslov" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>


                  <!--                  <div class="flex mt-5">-->
                  <!--                    <div class="w-3/4">-->
                  <!--                      <vs-input class="w-full" label="Naziv naslova (ime naselja, vasi oz. ustanove)"-->
                  <!--                                v-model="volunteersData.address_name"/>-->
                  <!--                    </div>-->
                  <!--                    <div class="w-1/4 ml-5">-->
                  <!--                      <vs-input class="w-full" label="Oddaljenost od mestnega središča" disabled-->
                  <!--                                v-model="volunteersData.address_distance_from_city_centre"/>-->
                  <!--                    </div>-->
                  <!--                  </div>-->


                  <div slot="header" class="p-0 text-primary mt-5">
                    Podrobnosti naslova
                  </div>

                  <div class="collapseBody mt-5">

                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Ulica" v-model="volunteersData.addresses.route"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.route.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.route.required" :color="'danger'">
                              Polje "Ulica" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Hišna številka"
                                  v-model="volunteersData.addresses.street_number"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.street_number.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.street_number.required" :color="'danger'">
                              Polje "Hišna številka" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Naziv pošte" v-model="volunteersData.addresses.postal_town"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.postal_town.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.postal_town.required" :color="'danger'">
                              Polje "Naziv pošte" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Poštna številka"
                                  v-model="volunteersData.addresses.postal_code"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.postal_code.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.postal_code.required" :color="'danger'">
                              Polje "Poštna številka" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mb-4">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Občina" v-model="volunteersData.addresses.municipality"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.municipality.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.municipality.required" :color="'danger'">
                              Polje "Občina" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Država" v-model="volunteersData.addresses.country"/>

                        <div class="flex mt-1" v-if="$v.volunteersData.addresses.country.$dirty">
                          <div class="w-full">
                            <vs-alert :active="!$v.volunteersData.addresses.country.required" :color="'danger'">
                              Polje "Država" ne sme biti prazno.
                            </vs-alert>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Kontaktni podatki</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">
                  <div class="flex">
                    <div class="w-1/2">
                      <vs-input class="w-full" label="Telefon" v-model="volunteersData.phone"/>

                      <div class="flex mt-1" v-if="$v.volunteersData.phone.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.volunteersData.phone.required" :color="'danger'">
                            Polje "Telefon" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>
                    </div>

                    <div class="w-1/2 ml-5">
                      <vs-input class="w-full" label="Telefon 2" v-model="volunteersData.phone2"/>
                    </div>
                  </div>

                  <div class="vx-row mt-5">
                    <div class="vx-col w-full">
                      <vs-input class="w-full" label="Email" v-model="volunteersData.email"/>

                      <div class="flex mt-1" v-if="$v.volunteersData.email.$dirty">
                        <div class="w-full">
                          <vs-alert :active="!$v.volunteersData.email.email" :color="'danger'">
                            Polje "Email" mora vsebovati veljaven e-poštni naslov.
                          </vs-alert>
                          <vs-alert :active="!$v.volunteersData.email.required" :color="'danger'">
                            Polje "Email" ne sme biti prazno.
                          </vs-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Oprema prostovoljca</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">
                  <div class="flex">
                    <div class="w-full">
                      <table class="vs-table vs-table--tbody-table">
                        <thead class="vs-table--thead">
                        <tr>
                          <th style="width: 40px;" class="text-center">#</th>
                          <th style="width: 130px">Oprema</th>
                          <th style="width: 130px">Velikost</th>
                          <th style="width: 130px">Št. kosov</th>
                          <th style="width: 130px">Datum prejema</th>
                          <th style="width: 130px">Datum vrnitve</th>
                          <th>Akcije</th>
                        </tr>
                        </thead>
                        <tbody class="vs-table--tbody"
                               v-if="volunteersData.equipmentLog === null || volunteersData.equipmentLog === ''">
                        <tr>
                          <td class="text-center" colspan="7">
                            <vs-alert color="primary" icon-pack="feather" icon="icon-info" class="mt-10">
                              <span>Ni vnosov</span>
                            </vs-alert>
                          </td>
                        </tr>
                        </tbody>
                        <tbody class="vs-table--tbody"
                               v-if="volunteersData && volunteersData.equipmentLog && volunteersData.equipmentLog !== '' && volunteersData.equipmentLog !== []">

                        <tr v-if="volunteersData && volunteersData.equipmentLog"
                            v-for="(equipmentItemInTable, idx) in JSON.parse(volunteersData.equipmentLog)">
                          <td>{{ idx + 1 }}</td>
                          <td>{{
                              equipmentItemInTable.type ? equipmentTypeOptions.filter(item => item.id === equipmentItemInTable.type)[0].label : '/'
                            }}
                          </td>
                          <td>{{
                              equipmentItemInTable.size ? equipmentSizeOptions.filter(item => item.id === equipmentItemInTable.size)[0].label : '/'
                            }}
                          </td>
                          <td>{{ equipmentItemInTable.numberOfItems }}</td>
                          <td>{{
                              equipmentItemInTable.dateReceived ? moment(equipmentItemInTable.dateReceived).format('DD. MM. YYYY') : '/'
                            }}
                          </td>
                          <td>
                            {{
                              equipmentItemInTable.dateReturned ? moment(equipmentItemInTable.dateReturned).format('DD. MM. YYYY') : '/'
                            }}
                          </td>
                          <td>
                            <div class="vs-col vs-xs- vs-sm- vs-lg-"
                                 style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                              <vs-button size="small" color="primary" type="border" icon="edit"
                                         @click="() => {equipmentItem = equipmentItemInTable; equipmentItem.idx = idx; showEquipmentModal = true;}"
                                         class="my-5 mx-2">Uredi
                              </vs-button>
                              <vs-button size="small" color="danger" type="border" icon="remove"
                                         @click="deleteEquipmentItem(idx)"
                                         class="my-5 mx-2">Izbriši
                              </vs-button>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <vs-button size="small" color="primary" type="border" icon="add"
                                 @click="() => {equipmentItem = {
        idx: -1,
        type: null,
        size: null,
        numberOfItems: 1,
        dateReceived: null,
        dateReturned: null
      }; showEquipmentModal = true;}"
                                 class="my-5 mx-2">Dodaj opremo
                      </vs-button>

                      <vs-popup :title="equipmentItem.idx === -1 ? 'Dodaj opremo': 'Uredi opremo'"
                                :active.sync="showEquipmentModal">
                        <div>
                          <div class="grid grid-cols-1 mt-5">
                            <div class="vx-col w-full">
                              <label class="vs-input--label">
                                Vrsta opreme
                              </label>

                              <v-select :options="equipmentTypeOptions" class="" v-model="equipmentItem.type"
                                        :placeholder="'izberi vsto opreme'"
                                        :reduce="item => item.id"
                                        :getOptionLabel="option => option.label">
                                <template v-slot:option="option">
                                  <vs-list-item
                                    :title="option.label"></vs-list-item>
                                </template>

                                <template v-slot:selected-option="option">
                                  <vs-list-item
                                    :title="option.label"></vs-list-item>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                                  </template>
                                  <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                                </template>
                              </v-select>
                            </div>

                            <div class="vx-col w-full mt-3" v-if="[2,3,4,5,6,7].includes(equipmentItem.type)">
                              <label class="vs-input--label">
                                Velikost
                              </label>

                              <v-select :options="equipmentSizeOptions" class="" v-model="equipmentItem.size"
                                        :placeholder="'izberi velikost opreme'"
                                        :reduce="item => item.id"
                                        :getOptionLabel="option => option.label">
                                <template v-slot:option="option">
                                  <vs-list-item
                                    :title="option.label"></vs-list-item>
                                </template>

                                <template v-slot:selected-option="option">
                                  <vs-list-item
                                    :title="option.label"></vs-list-item>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                                  </template>
                                  <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                                </template>
                              </v-select>
                            </div>

                            <div class="vx-col w-full mt-3">
                              <label class="vs-input--label">
                                Število kosov
                              </label>

                              <v-select :options="Array.from({length: 5}, (_, i) => i + 1)" class=""
                                        v-model="equipmentItem.numberOfItems"
                                        :placeholder="'izberi število kosov'">
                                <template v-slot:option="option">
                                  <vs-list-item
                                    :title="option.label.toString()"></vs-list-item>
                                </template>

                                <template v-slot:selected-option="option">
                                  <vs-list-item
                                    :title="option.label.toString()"></vs-list-item>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                                  </template>
                                  <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                                </template>
                              </v-select>
                            </div>

                            <div class="flex mt-3 gap-5">
                              <div class="w-1/2">
                                <label class="vs-input--label">Datum prejema</label>
                                <datepicker placeholder="Datum prejema"
                                            :monday-first="true"
                                            :language="slSI" :format="customDateFormatter"
                                            v-model="equipmentItem.dateReceived"></datepicker>
                              </div>
                              <div class="w-1/2">
                                <label class="vs-input--label">Datum vrnitve</label>
                                <datepicker placeholder="Datum vrnitve"
                                            :monday-first="true"
                                            :language="slSI" :format="customDateFormatter"
                                            v-model="equipmentItem.dateReturned"></datepicker>
                              </div>
                            </div>


                          </div>
                          <div class="grid grid-cols-1 mt-20">
                            <div>
                              <vs-button v-if="equipmentItem.idx === -1" size="medium" color="success" icon="check"
                                         @click="addEquipmentItem()">
                                <span>Dodaj</span>
                              </vs-button>

                              <vs-button v-if="equipmentItem.idx > -1" size="medium" color="success" icon="check"
                                         @click="updateEquipmentItem()">
                                <span>Posodobi</span>
                              </vs-button>
                            </div>
                          </div>
                        </div>
                      </vs-popup>

                    </div>
                  </div>

                  <div class="vx-row mt-5">
                    <div class="vx-col w-full">
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="vx-card mt-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Podatki za povračanje stroškov prihoda na delo</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">
                  <div class="grid grid-cols-1 mt-5">
                    <label for="travelCostsRefund" class="vs-input--label mb-2">Prostovoljec je upravičen do nadomestila
                      za
                      prihod na prostovoljsko delo in ga želi prejemati</label>
                    <vs-switch class="ml-1" color="success" v-model="volunteersData.travelCostsRefund"
                               id="travelCostsRefund">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                  <div v-if="volunteersData.travelCostsRefund">

                    <div class="vx-row mt-5">
                      <div class="vx-col w-full">
                        <vs-input class="w-full" label="Št. transkcijskega računa"
                                  v-model="volunteersData.volunteerBankAccount"/>
                      </div>
                    </div>

                    <div class="grid grid-cols-1 mt-5">
                      <label for="handleBusinessCarLocation" class="vs-input--label mb-2">Mesto prevzemanja službenega
                        vozila</label>

                      <ul class="genderPicker" id="handleBusinessCarLocation">
                        <li class="ml-1">
                          <vs-radio v-model="handleBusinessCarLocation" vs-name="handleBusinessCarLocation"
                                    :vs-value="'0'">na
                            mestu sedeža enote
                          </vs-radio>
                        </li>
                        <li class="ml-10">
                          <vs-radio v-model="handleBusinessCarLocation" vs-name="handleBusinessCarLocation"
                                    :vs-value="'1'">na
                            drugem naslovu
                          </vs-radio>
                        </li>
                      </ul>

                    </div>

                    <div class="vx-card mt-5" v-if="handleBusinessCarLocation === '1'">
                      <div class="vx-card__header">
                        <div class="vx-card__title">
                          <h4 class="">Naslov mesta prevzemanja vozila</h4>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <div class="vx-card__collapsible-content">
                        <div class="vx-card__body">

                          <div class="grid grid-cols-1">
                            <label for="businessCarLocationAddress" class="vs-input--label">Naslov</label>

                            <gmap-autocomplete
                              ref="businessCarLocationAddress"
                              id="businessCarLocationAddress"
                              @place_changed="(data) => { getAddressData(data, 'businessCarLocation') }"
                              placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                              :value="volunteersData.businessCarLocationAddress.quick_search"
                              :options="{componentRestrictions: { country: 'si' }}"
                              :select-first-on-enter="true"
                              class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                          </div>

                          <div slot="header" class="p-0 text-primary mt-5">
                            Podrobnosti naslova
                          </div>

                          <div class="collapseBody mt-5">

                            <div class="flex mb-4">
                              <div class="w-3/4">
                                <vs-input class="w-full" label="Ulica"
                                          v-model="volunteersData.businessCarLocationAddress.route"/>
                              </div>
                              <div class="w-1/4 ml-5">
                                <vs-input class="w-full" label="Hišna številka"
                                          v-model="volunteersData.businessCarLocationAddress.street_number"/>
                              </div>
                            </div>
                            <div class="flex mb-4">
                              <div class="w-3/4">
                                <vs-input class="w-full" label="Naziv pošte"
                                          v-model="volunteersData.businessCarLocationAddress.postal_town"/>
                              </div>
                              <div class="w-1/4 ml-5">
                                <vs-input class="w-full" label="Poštna številka"
                                          v-model="volunteersData.businessCarLocationAddress.postal_code"/>
                              </div>
                            </div>
                            <div class="flex mb-4">
                              <div class="w-3/4">
                                <vs-input class="w-full" label="Občina"
                                          v-model="volunteersData.businessCarLocationAddress.municipality"/>
                              </div>
                              <div class="w-1/4 ml-5">
                                <vs-input class="w-full" label="Država"
                                          v-model="volunteersData.businessCarLocationAddress.country"/>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="flex mt-5">
                      <div class="w-1/2">

                        <label class="vs-input--label">Dnevna razdalja</label>
                        <vx-input-group>
                          <vs-input class="w-full" v-model="volunteersData.dailyTravelDistance"/>

                          <template slot="append">
                            <div class="append-text bg-primary">
                              <span>km</span>
                            </div>
                          </template>
                        </vx-input-group>

                      </div>
                      <div class="w-1/2 ml-5">


                        <label class="vs-input--label">Dnevni potni strošek</label>
                        <vx-input-group>
                          <vs-input class="w-full" v-model="volunteersData.dailyTravelCost"/>

                          <template slot="append">
                            <div class="append-text bg-primary">
                              <span>EUR</span>
                            </div>
                          </template>
                        </vx-input-group>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="flex mt-5">
              <div class="w-full">
                <label class="vs-input--label">Opombe o prostovoljcu</label>
                <vs-textarea class="w-full" rows="6" v-model="volunteersData.notes"/>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3" v-if="volunteersData">
          <template>


            <div class="vx-card mt-10" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Slika</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div v-if="file" class="mt-2 text-center ml-auto mr-auto">
                    <vs-button :color="viewMode === 'image' ? 'grey' : 'primary'" size="small" class="mr-1"
                               @click="() => { viewMode = 'image'}">Predogled
                    </vs-button>
                    <vs-button :color="viewMode === 'crop' ? 'grey' : 'primary'" @click="() => { viewMode = 'crop'}"
                               class="ml-1" size="small">
                      Izreži & nastavi velikost
                    </vs-button>
                  </div>

                  <div class="w-full flex items-center">
                    <vs-avatar v-if="!logo" size="250px" class="ml-auto mr-auto"/>
                    <vs-avatar v-if="logo" size="250px"
                               :src="logo" class="ml-auto mr-auto"/>
                  </div>

                  <div>

                    <div
                      class="img-fluid img-thumbnail mt-2"
                      v-if="logo && viewMode === 'crop'">
                      <vue-cropper
                        ref="cropper"
                        :src="logo"
                        :aspectRatio="1"
                        alt="Source Image">
                      </vue-cropper>

                      <vs-button size="small" class="mt-2 mb-1 float-left" color="grey" @click="cancelCrop"><i
                        class="fa fa-times"></i> Prekliči
                      </vs-button>
                      <vs-button size="small" class="mt-2 mb-1 float-right" color="success" @click="confirmCrop"><i
                        class="fa fa-check"></i> Potrdi in shrani
                      </vs-button>
                    </div>
                  </div>

                  <div class="w-full flex items-center mt-3" v-if="viewMode === 'image'">
                    <input type="file" ref="file" @change="fileChanged" accept="image/jpeg, image/png, image/gif"
                           class="ml-auto mr-auto"/>
                  </div>

                </div>
              </div>
            </div>

            <div class="vx-card mt-10" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Bivališče in mesto prevzema vozila</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-0">

                  <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                                      @closeclick="infoWinOpen=false"/>
                    <GmapMarker
                      :key="index"
                      v-for="(item, index) in markers"
                      :position="item ? item.position : null"
                      :clickable="true"
                      :draggable="false"
                      @click="toggleInfoWindow(item, index)"
                    />

                    <DirectionsRenderer/>


                  </gmap-map>

                </div>
              </div>
            </div>

            <!--            <div class="vx-card mt-10" style="overflow: hidden">-->
            <!--              <div class="vx-card__header">-->
            <!--                <h4 class="text-success mb-0">Soglasja {{!volunteersData.gender || volunteersData.gender === 'male' ?-->
            <!--                  'prostovoljca' : 'prostovoljke'}}</h4>-->
            <!--              </div>-->
            <!--              <div class="vx-card__collapsible-content">-->
            <!--                <div class="vx-card__body">-->

            <!--                  <div v-if="volunteersData && volunteersData.id">-->
            <!--                    <ConsentsWidget :user-id="volunteersData.id" :consent-giver="'VOLUNTEER'" :mode="'edit'"></ConsentsWidget>-->
            <!--                  </div>-->

            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->


          </template>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
            <vs-button size="large" color="success" icon="save" @click="saveVolunteer(true)">Shrani</vs-button>
          </vs-col>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
import ConsentsWidget from "../consentManagement/ConsentWidget";
import vSelect from "vue-select";
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {minLength, maxLength, email, required} from "vuelidate/lib/validators";
import VueCropper from "vue-cropperjs";

let directionsService = null;
let directionsDisplay = null;
let geoCoder = null;

export default {
  name: 'volunteer-edit',

  components: {
    VueCropper,
    DirectionsRenderer,
    ConsentsWidget,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
  },
  props: {
    volunteerId: Number
  },
  data() {
    return {
      slSI: slSI,
      center: {lat: 46.0569, lng: 14.5058},
      radios1: 'luis',

      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      routeDistanceTotal: null,

      volunteerData: null,
      volunteer: null,

      markers: [],

      employmentStatuses: null,
      employmentStatusId: null,
      handleBusinessCarLocation: '0',

      viewMode: 'image',
      file: null,
      fileCropped: null,
      logo: null,

      volunteersData: {
        "id": null,
        "first_name": null,
        "last_name": null,
        "vat": null,
        "phone": null,
        "phone2": null,
        "email": null,
        "notes": null,
        "address_id": null,
        "unit_id": null,
        "volunteers": null,
        "gender": 'female',
        "active": true,
        "on_hold": false,
        "vaccinated": false,
        "addresses": {
          quick_search: null
        },
        "businessCarLocationAddress": {
          quick_search: null
        }
      },

      showEquipmentModal: false,
      equipmentTypeOptions: [
        {
          id: 1,
          label: 'ID izkaznica',
          type: 'id_card'
        },
        {
          id: 2,
          label: 'majica s kratkimi rokavi',
          type: 'clothes'
        },
        {
          id: 3,
          label: 'polo majica s kratkimi rokavi',
          type: 'clothes'
        },
        {
          id: 4,
          label: 'softshell jakna',
          type: 'clothes'
        },
        {
          id: 5,
          label: 'flis jakna',
          type: 'clothes'
        },
        {
          id: 6,
          label: 'bunda',
          type: 'clothes'
        },
        {
          id: 7,
          label: 'brezrokavnik',
          type: 'clothes'
        }
      ],
      equipmentSizeOptions: [
        {
          id: 1,
          label: 'XS',
        },
        {
          id: 2,
          label: 'S',
        },
        {
          id: 3,
          label: 'M',
        },
        {
          id: 4,
          label: 'L',
        },
        {
          id: 5,
          label: 'XL',
        },
        {
          id: 6,
          label: 'XXL',
        },
        {
          id: 7,
          label: '3XL',
        },
        {
          id: 8,
          label: '4XL',
        }
      ],
      equipmentItem: {
        idx: null,
        type: null,
        size: null,
        numberOfItems: 1,
        dateReceived: null,
        dateReturned: null
      },
    }
  },

  validations: {
    employmentStatusId: {
      required
    },
    volunteersData: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      vat: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(8)
      },
      phone: {
        required
      },
      email: {
        required,
        email
      },
      addresses: {
        quick_search: {
          required
        },
        route: {
          required
        },
        street_number: {
          required
        },
        postal_code: {
          required
        },
        postal_town: {
          required
        },
        municipality: {
          required
        },
        country: {
          required
        }
      }
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.checkIfGoogleMapsApiLoaded().then((res) => {
      console.log("DONE");

      if (_this.volunteerId) {
        _this.getEmploymentStatuses().then(() => {
          _this.getVolunteer();
        });
      } else {
        _this.getEmploymentStatuses();
        console.log("kaj pa zdej!?!");
      }
    });
  },

  watch: {
    handleBusinessCarLocation(val) {
      console.log(val);
      this.setMapsDirections();
    }
  },

  methods: {
    async checkIfGoogleMapsApiLoaded() {
      const _this = this;

      let promise = new Promise(function (resolve, reject) {
        if (typeof google !== 'undefined') {
          resolve("done")
          _this.showUnitMarker();
        } else {
          setTimeout(() => {
            _this.checkIfGoogleMapsApiLoaded()
          }, 400);
        }
      });
    },
    showVolunteerMarker() {
      const _this = this;

      geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({'address': _this.volunteersData.addresses.quick_search}, function (results, status) {
        if (status === 'OK') {
          _this.center = results[0].geometry.location;

          console.log("_this.center", results[0].geometry.location);

          let position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }

          _this.markers.push({
            infoText: _this.volunteersData.first_name + ' ' + _this.volunteersData.last_name,
            position: position
          });

          _this.setMapsDirections();

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });

    },

    async showUnitMarker() {
      const _this = this;

      const carsLocation = (_this.handleBusinessCarLocation === '1' && _this.volunteersData.businessCarLocationAddress && _this.volunteersData.businessCarLocationAddress.quick_search) ? _this.volunteersData.businessCarLocationAddress.quick_search : (_this.volunteersData.units ? _this.volunteersData.units.addresses.quick_search : null);

      if (carsLocation) {
        return
      }

      geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({'address': carsLocation}, function (results, status) {
        if (status === 'OK') {
          if (!_this.volunteersData.addresses.quick_search) {
            _this.center = results[0].geometry.location;
          }

          let position = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }

          _.remove(_this.markers, item => item.infoText === 'Mesto prevzema vozila');

          _this.markers.push({
            infoText: 'Mesto prevzema vozila',
            position: position
          });

        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    },

    async getEmploymentStatuses() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'employmentStatus')
        .then((res) => {
          _this.employmentStatuses = res.data.data;

          _this.$vs.loading.close();
          console.log("employmentStatuses: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getVolunteer() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.employmentStatusId = res.data.data.employment_status_id;

          if (res.data.data.businessCarLocation_id) {
            _this.handleBusinessCarLocation = "1";
          }

          if (!res.data.data.businessCarLocationAddress) {

            _this.volunteersData.businessCarLocationAddress = {
              quick_search: null
            }
          }

          _this.showVolunteerMarker();
          _this.logo = _this.$globalFunctions.getImage(_this.volunteersData.profile_image);
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },


    setMapsDirections() {
      const _this = this;

      if (!directionsService) {
        directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer;
        directionsDisplay.setMap(_this.$refs.map.$mapObject);
      }

      console.log("_this.volunteersData", _this.volunteersData);

      directionsService.route({
        origin: _this.volunteersData.addresses.quick_search,
        destination: (_this.handleBusinessCarLocation === '1' && _this.volunteersData.businessCarLocationAddress && _this.volunteersData.businessCarLocationAddress.quick_search) ? _this.volunteersData.businessCarLocationAddress.quick_search : _this.volunteersData.units.addresses.quick_search,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      }, function (response, status) {
        if (status === 'OK') {
          console.log("_this.volunteersData", _this.volunteersData);
          console.log(response);
          _this.volunteersData.dailyTravelDistance = parseFloat((response.routes[0].legs[0].distance.value) * 0.001 * 2).toFixed(2);
          _this.volunteersData.dailyTravelCost = _this.$globalFunctions.roundNumber(_this.volunteersData.dailyTravelDistance * 0.21, 2);
          _this.$forceUpdate();
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });

      _this.showUnitMarker();
    },


    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressSection", addressSection);

      if (addressSection === 'volunteers') {

        console.log("here");

        document.getElementById(addressSection + "Address").value = addressData.formatted_address;

        console.log("addressData.formatted_address;", addressData.formatted_address);

        _this.volunteersData.addresses.quick_search = addressData.formatted_address;
        _this.volunteersData.addresses.route = addressData.address_components[1].long_name;
        _this.volunteersData.addresses.street_number = addressData.address_components[0].long_name;
        _this.volunteersData.addresses.postal_town = addressData.address_components[2].long_name;
        _this.volunteersData.addresses.postal_code = addressData.address_components[5].long_name;
        _this.volunteersData.addresses.municipality = addressData.address_components[3].long_name;
        _this.volunteersData.addresses.country = addressData.address_components[4].long_name;


        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }

        let position = {
          'lat': addressData.geometry.location.lat(),
          'lng': addressData.geometry.location.lng()
        };

        _this.markers[0] = {
          infoText: 'Bivališče',
          position: position
        };
        _this.center = position;

        _this.$forceUpdate();

        _this.setMapsDirections();


      } else if (addressSection === 'businessCarLocation') {

        document.getElementById(addressSection + "Address").value = addressData.formatted_address;
        _this.volunteersData.businessCarLocationAddress.quick_search = addressData.formatted_address;
        _this.volunteersData.businessCarLocationAddress.route = addressData.address_components[1].long_name;
        _this.volunteersData.businessCarLocationAddress.street_number = addressData.address_components[0].long_name;
        _this.volunteersData.businessCarLocationAddress.postal_town = addressData.address_components[2].long_name;
        _this.volunteersData.businessCarLocationAddress.postal_code = addressData.address_components[5].long_name;
        _this.volunteersData.businessCarLocationAddress.municipality = addressData.address_components[3].long_name;
        _this.volunteersData.businessCarLocationAddress.country = addressData.address_components[4].long_name;


        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }

        let position = {
          'lat': addressData.geometry.location.lat(),
          'lng': addressData.geometry.location.lng()
        };

        _this.markers[1] = {
          infoText: 'Mesto prevzema vozila',
          position: position
        };
        _this.center = position;

        _this.$forceUpdate();

        _this.setMapsDirections();

      }

      console.log("addressData", addressData);
    },

    async saveVolunteer(returnToVolunteersList) {
      const _this = this;

      _this.$forceUpdate();

      _this.$v.$touch();
      _this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      _this.$vs.loading();

      let volunteer = _.omit(_this.volunteersData, ['disabled', 'deleted', 'created_at', 'updated_at']);
      volunteer.address = _this.volunteersData.addresses;
      volunteer.unit_id = _this.volunteersData.unit_id ? _this.volunteersData.unit_id : _this.$globalFunctions.getCurrentUnit().id;
      volunteer.employment_status_id = _this.employmentStatusId;
      volunteer.internal_app_id = 'n/a';
      volunteer.internal_app_image_url = 'n/a';

      volunteer.travelCostsRefund = (_this.volunteersData.travelCostsRefund === 1 || _this.volunteersData.travelCostsRefund) ? true : false;

      if (volunteer.travelCostsRefund) {
        if (_this.handleBusinessCarLocation === '1') {
          volunteer.businessCarLocation_id = _this.volunteersData.businessCarLocation_id ? _this.volunteersData.businessCarLocation_id : '-1';
          volunteer.businessCarLocationAddress = _this.volunteersData.businessCarLocationAddress;
        } else {
          volunteer.businessCarLocation_id = null;
          volunteer.businessCarLocationAddress = null;
        }
      } else {
        volunteer.dailyTravelCost = null;
        volunteer.dailyTravelDistance = null;
        volunteer.businessCarLocation_id = null;
        volunteer.businessCarLocationAddress = null;
      }

      if(!volunteer.id) {
        volunteer.accessDataSent = false;
      }

      // volunteer.businessCarLocation_id =  _this.volunteersData.businessCarLocation_id ?  _this.volunteersData.businessCarLocation_id : '-1';
      // console.log("_this.handleBusinessCarLocation", _this.handleBusinessCarLocation);
      // volunteer.businessCarLocationAddress = _this.handleBusinessCarLocation === '1' ? _this.volunteersData.businessCarLocationAddress : null;
      // console.log("_this.businessCarLocationAddress",_this.volunteersData.businessCarLocationAddress);

      // volunteer.travelCostsRefund = _this.

      console.log(volunteer);

      await Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'volunteers', volunteer)
        .then((res) => {

          console.log("res", res);
          _this.$vs.loading.close();
          if (returnToVolunteersList) {
            _this.$router.push({name: 'Prostovoljci'});
          }

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    fileChanged() {
      const _this = this;
      let file = _this.$refs.file;
      _this.file = file.files[0];
      _this.viewMode = 'crop';
      _this.logo = URL.createObjectURL(file.files[0])

    },

    confirmCrop() {
      const _this = this;
      _this.$vs.loading();

      _this.logo = _this.$refs.cropper
        .getCroppedCanvas({
          width: 500,
          height: 500
        })
        .toDataURL();

      _this.$refs.cropper
        .getCroppedCanvas({
          width: 500,
          height: 500
        })
        .toBlob(async blob => {
          _this.fileCropped = new File([blob], _this.file.name);

          let formData = new FormData();
          formData.append('profile_image', new File([blob], _this.file.name));

          await Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'volunteersImageUpload', formData)
            .then(async (res) => {

              console.log("RES!!!!!", res)
              _this.volunteersData.profile_image = res.data.data.fileId;

              if (_this.volunteerId) {
                await _this.saveVolunteer(false);
                _this.file = null;
                _this.fileCropped = null;
                _this.viewMode = 'image';
                _this.$vs.loading.close();
              } else {
                _this.file = null;
                _this.fileCropped = null;
                _this.viewMode = 'image';
                _this.$vs.loading.close();
              }
            })
            .catch((error) => {
              console.log("error", error);
              _this.$vs.loading.close();
              return false
            });


        });
      _this.viewMode = 'image';
    },
    cancelCrop() {
      const _this = this;
      _this.logo = URL.createObjectURL(_this.file);
      _this.fileCropped = null;
      _this.viewMode = 'image';
    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert');

      if (domRect) {
        domRect.getBoundingClientRect()

        window.scrollTo(
          domRect.left + document.documentElement.scrollLeft,
          domRect.top + document.documentElement.scrollTop - 200, 'smooth'
        );
      }
    },

    addEquipmentItem() {
      let equipmentLog = this.volunteersData && this.volunteersData.equipmentLog ? JSON.parse(this.volunteersData.equipmentLog) : [];
      equipmentLog.push(this.equipmentItem);
      this.volunteersData.equipmentLog = JSON.stringify(equipmentLog);

      this.showEquipmentModal = false;
      this.equipmentItem = {
        idx: null,
        type: null,
        size: null,
        numberOfItems: 1,
        dateReceived: null,
        dateReturned: null
      };
    },

    updateEquipmentItem() {
      let equipmentLog = JSON.parse(this.volunteersData.equipmentLog);
      equipmentLog[this.equipmentItem.idx] = _.omit(this.equipmentItem, ['idx']);
      this.volunteersData.equipmentLog = JSON.stringify(equipmentLog);

      this.showEquipmentModal = false;
      this.equipmentItem = {
        idx: null,
        type: null,
        size: null,
        numberOfItems: 1,
        dateReceived: null,
        dateReturned: null
      };
    },

    deleteEquipmentItem(idx) {
      let equipmentLog = JSON.parse(this.volunteersData.equipmentLog);
      equipmentLog.splice(idx, 1);
      this.volunteersData.equipmentLog = JSON.stringify(equipmentLog);
    }

  }
}
</script>

